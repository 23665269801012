const constants = {
  brandInfo: {
    brandName: 'MakerX',
    brandLink: 'https://makerx.com.au/',
    brandLinkName: 'www.makerx.com.au',
    brandLinkColour: '#0E39FB',
    brandLogo: {
      link: 'https://blog.makerx.com.au/content/images/2022/01/makerx_Full.png',
      alt: 'MakerX',
    },
    brandGPTWLogo: {
      link: 'https://blog.makerx.com.au/content/images/size/w1000/2023/06/MakerX_2023_Certification_Badge-1.png',
      alt: 'MakerX Great Place to Work',
    },
  },

  placeholders: {
    name: 'Your Name',
    pronoun: 'he/him | she/her | they/them',
    title: 'Job Title',
    email: 'your.name@makerx.com.au',
    mobile: '+61 111 222 333',
    twitter: 'myTwitterHandle',
    qualifications: 'Jedi Master | PSM I',
    supportHotline: '1800 xyz xyz',
    supportEmail: 'support@some.domain',
  },

  default: {
    countryCode: '+61',
  },
};

export default constants;
